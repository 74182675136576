// Views/Layouts
import Website from '../views/Website.vue'
import Page404 from '../views/Page404.vue'

// Pages
import Home from '../pages/Home.vue'
import SendParcel from '../pages/SendParcel.vue'
import TrackParcel from '../pages/TrackParcel.vue'
import Cart from '../pages/Cart.vue'
import Checkout from '../pages/Checkout.vue'
// Policies
import Cookies from '../pages/Cookies.vue'
import Payments from '../pages/Payments.vue'
import GDPR from '../pages/GDPR.vue'
import ToS from '../pages/ToS.vue'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  //   name: 'Website'
  // },
  {
    path: '/',
    component: Website,
    children: [
      {
        path: '/',
        name: 'Home',
        components: { default: Home },
      },
      {
        path: 'send',
        name: 'SendParcel',
        components: { default: SendParcel },
      },
      {
        path: 'track',
        name: 'TrackParcel',
        components: { default: TrackParcel },
      },
      {
        path: 'cart',
        name: 'cart',
        components: { default: Cart },
      },
      {
        path: 'checkout',
        props: route => ({ 
          parcel_invoice: route.query.parcel_invoice, 
          phone_number: route.query.phone_number,
          pcode: route.query.pcode,
        }),
        name: 'Checkout',
        components: { default: Checkout },
      },
      {
        path: 'cookies',
        name: 'Cookies',
        components: { default: Cookies },
      },
      {
        path: 'payments',
        name: 'Payments',
        components: { default: Payments },
      },
      {
        path: 'gdpr',
        name: 'GDPR',
        components: { default: GDPR },
      },
      {
        path: 'terms-of-service',
        name: 'TermsOfService',
        components: { default: ToS },
      },
    ]
  },
  {
    path: '*', 
    name: 'Page404',
    component: Page404
  },
]

export default routes

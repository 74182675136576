<template>
    <v-main id='about' fluid>
        <v-container grid-list-lg style='margin-bottom:15px;'>
            <v-layout row wrap align-center justify-center>

            <v-flex xs12 sm12 md6>
                <v-img
                src="../../assets/images/Asset-5.jpg"
                contain
                max-height="400"
                max-width="400"
                style='margin-top:10px;margin-bottom:10px'
                ></v-img>
            </v-flex>
    
            <v-flex xs12 sm12 md6>
                <span class="text-md-h3 text-sm-h4 font-weight-black primary--text text-xs-center my-1">
                    {{ $vuetify.lang.t('$vuetify.home.about.section_1.title') }}
                </span><br><br>
                    {{ $vuetify.lang.t('$vuetify.home.about.section_1.body1') }}
                <br>
                <!-- <v-divider></v-divider><br>
                <strong>
                    {{ $vuetify.lang.t('$vuetify.home.about.section_1.body2') }} <a href='mailto:info@pratkabg.com'>info@pratkabg.com</a>
                </strong> -->
            </v-flex>

            <v-flex xs12 sm12 md6>
                <span class="text-md-h3 text-sm-h4 font-weight-black primary--text text-xs-center my-1">
                    {{ $vuetify.lang.t('$vuetify.home.about.section_2.title') }}
                </span><br><br><span class="text-md-h5 text-sm-h6 font-weight-black info--text text-xs-center my-3 md-5">
                    {{ $vuetify.lang.t('$vuetify.home.about.section_2.subtitle') }}
                </span><br><br>
                    {{ $vuetify.lang.t('$vuetify.home.about.section_2.body') }}
            </v-flex>
            <v-flex xs12 sm12 md6>
                <v-img
                src="../../assets/images/d1.jpg"
                contain
                max-height="400"
                max-width="400"
                style='margin-top:10px;margin-bottom:10px'
                ></v-img>
            </v-flex>

            <v-flex xs12 sm12 md6>
                <v-img
                src="../../assets/images/lofo-2.svg"
                contain
                max-height="400"
                max-width="400"
                style='margin-top:10px;margin-bottom:10px'
                ></v-img>
            </v-flex>
            <v-flex xs12 sm12 md6>
                <span class="text-md-h5 text-sm-h6 font-weight-black info--text text-xs-center my-3 md-5">
                    {{ $vuetify.lang.t('$vuetify.home.about.section_3.subtitle') }}
                </span><br><br>
                    {{ $vuetify.lang.t('$vuetify.home.about.section_3.body') }}
            </v-flex>

            <v-flex xs12 sm12 md6>
                <span class="text-md-h5 text-sm-h6 font-weight-black info--text text-xs-center my-3 md-5">
                    {{ $vuetify.lang.t('$vuetify.home.about.section_4.subtitle') }}
                </span><br><br>
                    {{ $vuetify.lang.t('$vuetify.home.about.section_4.body') }}
            </v-flex>
            <v-flex xs12 sm12 md6>
                <v-img
                src="../../assets/images/d3_ua.png"
                contain
                max-height="400"
                max-width="400"
                style='margin-top:10px;margin-bottom:10px'
                ></v-img>
            </v-flex>

            <v-flex xs12 sm12 md6>
                <v-img
                src="../../assets/images/d4_transp.png"
                contain
                max-height="400"
                max-width="400"
                style='margin-top:10px;margin-bottom:10px'
                ></v-img>
            </v-flex>
            <v-flex xs12 sm12 md6>
                <span class="text-md-h5 text-sm-h6 font-weight-black info--text text-xs-center my-3 md-5"> 
                {{ $vuetify.lang.t('$vuetify.home.about.section_5.subtitle') }}
                </span><br><br>
                {{ $vuetify.lang.t('$vuetify.home.about.section_5.body') }}
            </v-flex>

            </v-layout>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'HomeAbout',
    data() {
        return {
        };
    },
};
</script>
